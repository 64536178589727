import { watchEffect, watch } from 'vue'

import { 
  createWebHistory, 
  createWebHashHistory, 
  createRouter,
  useRouter
} from 'vue-router'
import { userAuth } from '@/reactive'

// 普惠空间公众号授权
const phkjAuthRoutes = [
  {
    path: '/phkjAuth/bindAuth',
    component: () => import('@/views/phkjAuth/bindAuth'),
    meta: {
      title: '绑定授权'
    }
  },
  {
    path: '/phkjAuth/eleRetailGuide',
    component: () => import('@/views/phkjAuth/eleRetailGuide'),
    meta: {
      title: '饿了么零售店铺授权教程'
    }
  },
  {
    path: '/phkjAuth/bindTransport',
    component: () => import('@/views/phkjAuth/bindTransport'),
    meta: {
      title: '自有运力绑定'
    }
  },
  {
    path: '/phkjAuth/bindTransportLazipig',
    component: () => import('@/views/phkjAuth/bindTransportLazipig'),
    meta: {
      title: '自有运力绑定'
    }
  },
  {
    path: '/phkjAuth/bindTransportLazipigCallback',
    component: () => import('@/views/phkjAuth/bindTransportLazipigCallback'),
    meta: {
      title: '自有运力绑定'
    }
  },
  {
    path: '/phkjAuth/eleAuthCallback',
    component: () => import('@/views/phkjAuth/eleAuthCallback'),
    meta: {
      title: '饿了么授权结果'
    }
  },
]

// app内嵌页
const blendRoutes = [
  {
    path: '/blend-view/about',
    component: () => import('@/views/blend-view/about'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/blend-view/agreement',
    component: () => import('@/views/blend-view/agreement'),
    meta: {
      title: ''
    }
  }, 
  {
    path: '/blend-view/element-callback',
    component: () => import('@/views/blend-view/element-callback'),
    meta: {
      title: '饿了么回调'
    }
  }, 
  {
    path: '/blend-view/shansongCallback',
    component: () => import('@/views/blend-view/shansongCallback'),
    meta: {
      title: '闪送授权回调'
    }
  }, 
  {
    path: '/blend-view/dadaCallback',
    component: () => import('@/views/blend-view/dadaCallback'),
    meta: {
      title: '达达授权回调'
    }
  }, 
  {
    path: '/blend-view/rechargeInfo',
    component: () => import('@/views/blend-view/rechargeInfo'),
    meta: {
      title: '富文本内容'
    }
  },
  {
    path: '/blend-view/ownChargingInfo',
    component: () => import('@/views/blend-view/ownChargingInfo'),
    meta: {
      title: '自有运力收费说明'
    }
  },
  {
    path: '/blend-view/exceptionGuide',
    component: () => import('@/views/blend-view/exceptionGuide'),
    meta: {
      title: '达达授权异常处理'
    }
  },
  {
    path: '/blend-view/automaticBillingInfo',
    component: () => import('@/views/blend-view/automaticBillingInfo'),
    meta: {
      title: '自动发单说明'
    }
  },
  {
    path: '/blend-view/lazipigAuthorization',
    component: () => import('@/views/blend-view/lazipigAuthorization'),
    meta: {
      title: '懒猪快送授权'
    }
  },
  {
    path: '/blend-view/lazipigCallback',
    component: () => import('@/views/blend-view/lazipigCallback'),
    meta: {
      title: '懒猪快送授权回调'
    }
  },
  {
    path: '/blend-view/pay-agreement',
    component: () => import('@/views/blend-view/pay-agreement'),
    meta: {
      title: '连连支付服务协议'
    }
  },
  {
    path: '/blend-view/rechargeSuccess',
    component: () => import('@/views/blend-view/rechargeSuccess'),
    meta: {
      title: '达达充值成功'
    }
  },
]

//猪耳朵相关页面
const pigearRoutes = [
  {
    path: '/pigear-signup',
    component: () => import('@/views/pigear/pigear-signup'),
    meta: {
      title: '猪耳朵注册'
    },
  },
  {
    path: '/errorUrl',
    component: () => import('@/views/pigear/errorUrl'),
    meta: {
      title: '错误页面'
    },
  },
  {
    path: '/signup-success',
    component: () => import('@/views/pigear/signup-success'),
    meta: {
      title: '注册成功'
    },
  },
]

//路由配置
const routes = [
  {
    path: '/',
    component: () => import('@/views/home'),
    meta: {
      title: '首页'
    },
  },
  {
    path: '/signup',
    component: () => import('@/views/signup'),
    meta: {
      title: '商户注册'
    }
  },
  {
    path: '/channel-signup',
    component: () => import('@/views/channel-signup'),
    meta: {
      title: '渠道商注册'
    }
  },

  {
    path: '/signin',
    component: () => import('@/views/signin'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/download',
    component: () => import('@/views/download'),
    meta: {
      title: '下载APP'
    }
  },
  {
    path: '/bind-bankcard',
    component: () => import('@/views/bind-bankcard'),
    meta: {
      title: '绑定银行卡'
    }
  },
  {
    path: '/forget',
    component: () => import('@/views/forget-password'),
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/invit-record',
    meta: {
      title: '邀请记录'
    },
    component: () => import('@/views/invit-record'),
  },
  {
    path: '/broker-record',
    meta: {
      title: '渠道记录'
    },
    component: () => import('@/views/broker-record'),
  },
  {
    path: '/settlement',
    component: () => import('@/views/settlement/index'),
    children: [{
      path: '',
      component: () => import('@/views/settlement/settlement'),
      meta: {
        title: ''
      }
    }, {
      path: 'detail',
      component: () => import('@/views/settlement/detail'),
      meta: {
        title: '结算明细'
      }
    },{
      path: 'cash-detail',
      component: () => import('@/views/settlement/cash-detail'),
      meta: {
        title: '支取详情'
      }
    }]
  },
  {
    path: '/income',
    redirect: '/income/draw-input',
    component: () => import('@/views/income/index'),
    children: [{
      path: 'draw-input',
      component: () => import('@/views/income/draw-input'),
      meta: {
        title: '收益提现'
      }
    },{
      path: 'draw-success',
      component: () => import('@/views/income/draw-success'),
      meta: {
        title: '收益提现详情'
      }
    }]
  },
  {
    path: '/old-verified',
    component: () => import('@/views/verified'),
    meta: {
      title: '实名认证'
    }
  },
  {
    path: '/verified',
    component: () => import('@/views/verified'),
    meta: {
      title: '实名认证'
    }
  },
  {
    path: '/pay',
    component: () => import('@/views/pay'),
    children: [{
      path: '',
      component: () => import('@/views/pay/pay'),
      meta: {
        title: '支付'
      }
    }, {
      path: 'result',
      component: () => import('@/views/pay/result'),
      meta: {
        title: '支付成功'
      }
    }]
  },
  {
    path: '/trust',
    component: () => import('@/views/trust/index'),
    children: [{
      path: '',
      meta: {
        title: '',
      },
      component: () => import('@/views/trust/trust')
    },
    {
      path: 'result',
      component: () => import('@/views/trust/trust-result'),
      meta: {
        title: '委托书状态'
      }
    }]
  },
  {
    path: '/open-service',
    component: () => import('@/views/open-service'),
    meta: {
      title: '开通服务'
    }
  },
  {
    path: '/success-fallback',
    component: () => import('@/views/success-fallback'),
    meta: {
      title: '注册成功'
    }
  },
  {
    path: '/merchant-promote',
    component: () => import('@/views/merchant-promote'),
    meta: {
      title: '商户推广'
    }
  },
  {
    path: '/face-to-face',
    component: () => import('@/views/face-to-face'),
    meta: {
      title: '面对面邀请'
    }
  },
  {
    path: '/channel-promote',
    component: () => import('@/views/channel-promote'),
    meta: {
      title: '渠道推广'
    }
  },
  {
    path: '/channel-invite',
    component: () => import('@/views/channel-invite'),
    meta: {
      title: '渠道推广'
    }
  },
  {
    path: '/shop-order',
    component: () => import('@/views/shop-order'),
    meta: {
      title: ''
    },
    children:[
      {
        path: '/shop-order/shop',
        component: () => import('@/components/shopList'),
        meta: {
          title: ''
        }
      },
      {
        path: '/shop-order/order',
        component: () => import('@/components/orderList'),
        meta: {
          title: ''
        }
      }
    ]
  },
  ...phkjAuthRoutes,
  ...blendRoutes,
  ...pigearRoutes,

  { 
    path: '/:catchAll(.*)', 
    meta: {
      title: 404
    },
    component: () => import('@/views/404'),
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  routes
})

// 特殊处理饿了么内嵌
function specIntercept(next) {
  if(window.location.host === 'eleh5.pig-ear.com') {
    return next('/blend-view/element-callback')
  }
}

router.beforeEach((to, from, next) => {
  let auth = window.localStorage.getItem('userAuth')
  
  // if(to.path === '/signin' || to.path === '/forget'){
  //   specIntercept(next)
  //   return next()
  //   //return next(auth ? from.path : null)
  // }
  if([
    '/signin',
    '/forget',
    '/signup', 
    '/channel-signup',
    '/success-fallback', 
    '/download',
    '/channel-invite',
    // '/blend-view/automaticBillingInfo',
    // '/blend-view/ownChargingInfo',
    '/blend-view/shansongCallback',
    '/blend-view/dadaCallback',
    '/blend-view/lazipigAuthorization',
    '/blend-view/lazipigCallback',
    '/pigear-signup',
    '/signup-success',
    '/errorUrl',

    '/phkjAuth/bindAuth',
    '/phkjAuth/eleRetailGuide',
    '/phkjAuth/bindTransport',
    '/phkjAuth/bindTransportLazipig',
    '/phkjAuth/bindTransportLazipigCallback',
    '/phkjAuth/eleAuthCallback',


  ].some(path => to.path === path)) {
    specIntercept(next)
    return next()
  }

  // if(to.path === '/signup') {
  //   specIntercept(next)
  //   return next()
  // }
  // if(to.path === '/download') {
  //   specIntercept(next)
  //   return next()
  // }
  // if(to.path === '/success-fallback') {
  //   specIntercept(next)
  //   return next()
  // }
  
  else {
    if(/^\/blend-view\/.+/.test(to.path)) {
      if(to.path !== '/blend-view/element-callback'){
        specIntercept(next)
      }
      next()
    }
    else {
      next(auth ? null : '/signin')
    }
  }  
 
  
})


export default router