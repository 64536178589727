import { http } from '.'
const uploadQNToken = async (data = {}) => {
  let result = await http.post('/?cmd=com.xqxc.api.pigear.biz.platform.QiniuUploadTokenService.qiNiuImgToken', data, {
    timeout:10000
  })
  return result
}

const qiNiuGetUrl = async (data, config) => {
  let result = await http.post('/?cmd=com.xqxc.api.pigear.biz.platform.QiniuUploadTokenService.qiNiuGetUrl', data)
  return result
}

const idcardOcr = async (data) => {
  let result = await http.post('/?cmd=com.xqxc.api.pigear.biz.account.BaiduAdminService.getInfoByIdCard', data)
  return result
}
const addCardInfo = async (data) => {
  let result = await http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerLoginService.addIdcard', data)
  return result
}
const getKefuTel = async () => {
  let result = await http.post('/?cmd=com.xqxc.api.pigear.biz.platform.KefuTelService.getKefuTel')
  return result
}

export {
  uploadQNToken,
  qiNiuGetUrl,
  idcardOcr,
  addCardInfo,
  getKefuTel
}