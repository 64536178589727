import { createApp, ref } from 'vue'
import router from '@/routes'
import VueSignaturePad from 'vue-signature-pad'
import App from './App.vue'
import axios from '@/http'
import Vant from 'vant'
import directives from '@/directives'
import '@/assets/css/global.less'
import 'vant/lib/index.less'

const app = createApp(App)
directives(app)
// Axios Inject
app.config.globalProperties.$http = axios

// import VConsole from 'vconsole';
// const vConsole = new VConsole();

app
  .use(router)
  .use(Vant)
  .use(VueSignaturePad)
  .mount('#app')









