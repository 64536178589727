<template>
  <main>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
    <van-overlay :show="loading && needLoading" style="background-color:transparent">
      <div class="wrapper" @click.stop>
        <van-loading color="#00ABEB" size="50" vertical />
      </div>
    </van-overlay>
  </main>
</template>

<script>
import { watch, ref, provide, onMounted } from 'vue' 
import { getKefuTel } from '@/http/api'
import { loading, needLoading } from '@/reactive'
import router from '@/routes'
import { useRoute } from 'vue-router'

export default {

  setup(props) {
    let route = useRoute()
    let serviceTel = ref('')

    watch(route, (a, b) => {
      document.title = a.meta.title
      let pattern = /^\/(?:(channel-)?sign(?:in|up)|trust|open-service|blend-view|success-fallback|draw-success|pigear)/
      if((pattern).test(a.path)) {
        document.body.className = ''
      }
      else {
        if(a.matched[0].meta.title === 404) {
          document.body.className = ''
        }
        else {
          document.body.className = 'gray'
        }
        
      }
      if(window.location.host !== 'eleh5.pig-ear.com') {
        if(a.path === '/signin' || a.path === '/' || a.path === '/channel-signup') {
          getKefuTel().then(res => {
            if(res.result) {
              serviceTel.value = res.result
            } 
          })
        }
        
      }
    })
    
    provide('serviceTel', serviceTel)
    
    return {
      loading,
      needLoading
    }
  },
  
}
</script>
<style>
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
