/**
 * [与app交互]
 * @author: Fu Xiaochun
 * @date: 2022-02-17 
 */

// js与原生APP交互
const APPJS = (funcName, params = {}) => {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 || u.indexOf('Windows'); //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //示例
    if (isIOS) {
      return window.webkit.messageHandlers.APPJS.postMessage({
        'fun': funcName,
        'args': params
      });
    }
    if (isAndroid) {
      let result = '';
      window.android && window.android[funcName] && (result = window.android[funcName](JSON.stringify(params)));
      return result;
    }
};

export {
    APPJS,
}