import axios from 'axios'
import { Dialog, Toast } from 'vant'
import router from '@/routes'
import { loading, needLoading, userAuth } from '@/reactive'
import { APPJS } from '@/utils/native';

let config = {
  baseURL: process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test' ? '/request' : '/',
  timeout: 30000,
  // withCredentials: true, 
}

const _axios = axios.create(config);

const getiOsToken = () => {
  let u = window.navigator.userAgent;
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isIOS) {
    return window.localStorage.getItem('token');
  }
  return APPJS('getToken');
};

// 判断是否是普惠空间相关的
const isPHKJApi = (url)=>{
    let list = [
      '/callback/authToken',
      '/callback/getJdkAuthUrl',
      '/callback/getAuthUrl'
    ];
    let status = false;
    list.forEach(v=>{
      if (url.indexOf(v) >= 0){
        status = true;
      }
    });
    return status;
};

const withoutTokenApi = (url)=>{
  let list = [
    'userLogin',
    '/elm/callback/authToken',
    'cmd=com.xqxc.api.pigear.biz.account.MerchantAuthService.addShopOrderSourceRef',
    'cmd=com.xqxc.api.pigear.biz.weixin.WeixinShareService.getPHZHApplet'
  ];
  let status = false;
  list.forEach(v=>{
    if(url.indexOf(v) >= 0){
      status = true;
    }
  });
  return status;
};

// Request Interceptor
_axios.interceptors.request.use((config) => {
  needLoading.value && (loading.value = true)
  if (/^\/blend-view\/.+$/.test(router.currentRoute._value.path)) {

    if (config.url.indexOf('/callback/authToken') >= 0) {
      config.url += ''
    } else if (config.url.indexOf('&group=earH5') > 0) {
      config.url += ''
    }
    else {
      config.url += '&group=earApp';
      config.headers = {
        token: getiOsToken(),
        // token: 'eyJhbGciOiJIUzI1NiIsIlR5cGUiOiJKd3QiLCJ0eXAiOiJKV1QifQ.eyJsb2dpbklkIjoyMTM0ODQxNzM4ODM4MzMzLCJleHAiOjE2Nzg1MjQ0MDh9.SX2jwelDPUG4ODG4uKq0a3xFyvk31oF5EQV3dHAAkGM'
      }
    }
  } else if (isPHKJApi(config.url)){
    config.url += '';
    if (!withoutTokenApi(config.url)) {
      config.headers = {
        token: JSON.parse(window.localStorage.getItem('phkjPigearAuth')).token
      };
    }
  } else if (config.url.indexOf('&group=earApp') > 0){
    config.url += '';
    if (!withoutTokenApi(config.url)){
      config.headers = {
        token: JSON.parse(window.localStorage.getItem('phkjPigearAuth')).token
      };
    }
  }
  else {
    config.url += '&group=earH5'
    if (!/^\/(?:sign(?:in|out)|forget)/.test(router.currentRoute._value.path)) {
      try {
        config.headers = {
          token: JSON.parse(window.localStorage.getItem('userAuth')).token
        }
      } catch (e) { }
    }

  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// Response Interceptor
_axios.interceptors.response.use((res) => {
  loading.value = false
  needLoading.value = false
    if (res.data.errCode !== 0) {
            // 饿了么的回调失败状态码匹配
                console.log(res.data);
            if ([90002, 90004, 90005, 45001, 45002, 45003, 45004, 45005].every(code => res.data.errCode !== code)) {
                if (4006 === res.data.errCode) {
                    if (res.data.cmd !== 'com.xqxc.api.pigear.biz.account.BrokerLoginService.checkRefId') {
                    Dialog.alert({
                        message: res.data.errDesc || res.data.errName
                    })
                    }
                    return Promise.reject(res.data);
                }
                else {
                        if (res.data.errDesc && res.data.errDesc.indexOf('kftel') == -1) {
                            Dialog.alert({
                                message: res.data.errDesc || res.data.errName
                            })
                        }
                    return Promise.reject(res.data);
                }

            }else {
                return Promise.reject(res.data);
            }



    }


  switch (res.data.errCode) {
    case 3002:
    case 3003:
    case 3004:
    case 3005:
    case 3006:
      Dialog.alert({
        message: res.data.errDesc || res.data.errName
      })
      userAuth.value = null
      window.localStorage.removeItem('userAuth')
      router.replace('/signin')
      break
    default:
      break
  }

  return res.data

}, (error) => {
  loading.value = false
  needLoading.value = false
  Dialog.alert({
    message: '网络超时或服务器内部错误'
  })
  return Promise.reject(error)
})

export const http = {
  get: async (url, config = {}) => {
    const res = await _axios.get(url, config)
    return res
  },
  post: async (url, data, config = {}) => {
    const res = await _axios.post(url, data, config)
    return res
  }
}

export default _axios

