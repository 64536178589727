import { http } from '.'
// 账号登录权限
const signIn = async (data) => {
  let result = await http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerLoginService.brokerLogin', { ...data })
  return result
}
const signUp = async (data) => {
  let result = await http.post('/?cmd=com.xqxc.api.pigear.biz.account.UserLoginService.userLogin', { ...data })
  return result
}
const sendCode = async (data) => {
  let result = await http.post('/?cmd=com.xqxc.api.pigear.biz.platform.CaptchaVerifyService.captchaVerify', {
    ...data
  })
  return result
}
const forgetPassword = async (data) => {
  let result = await http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerLoginService.setPassword', {
    ...data
  })
  return result
}
export {
  signIn,
  signUp,
  sendCode,
  forgetPassword
}