const directives = (app) => {
  
  app.directive('decimal', {
    mounted(el, binding) {
      
    },

    updated(el, binding) {
      let limit = binding.value.limit || 3
      if(binding.value.num === undefined) {
        el.innerHTML = (0).toFixed(limit)
      }
      else {
        el.innerHTML = Number(binding.value.num).toFixed(limit)
      }

    }
  })
}
export default directives